import React from "react";
import DefaultLayout from "../layouts";
import { graphql, Link } from "gatsby";
import * as styles from "./../styles/calculator.module.scss";
import { useState } from "react";
import InputSliderProps from 'react-input-slider';
import RoundedButton from '../components/sub-components/roundedButton';
import CurrencyInput from "react-currency-input-field";


const CalculatorPage = ({ data, location }) => {

    const loanOfficerReferrer = location?.state?.loanOfficer;

    const calculateDownPayment = (percentWhole, numerical) => {
        if (percentWhole) {
            const percent = percentWhole / 100;
            const numeric = percent * purchasePrice;
            setDownPaymentNumerical(numeric.toFixed(2));
        } else if (numerical) {
            setDownPaymentNumerical(parseFloat(numerical).toFixed(2));
        }

    }
    const validateInput = (value) => {
        if (value <= 0) {
            return 0;
        } else {
            return value;
        }

    }

    const formatNumber = inputNumber => {
        let formetedNumber = (Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return (formetedNumber);
    };
    const loanCalculator = data.directus.loanCalculator;

    // Inputs:
    // Numerical entry in dollars
    const [purchasePrice, setPurchasePrice] = useState(250000);
    // Input as dollars here...
    const [downPaymentNumerical, setDownPaymentNumerical] = useState(12500);
    // Dropdown selection of 15, 20, or 30 years
    const [mortgageTerm, setMortgageTerm] = useState(30);
    // Numerical entry in dollars
    const [annualTaxes, setAnnualTaxes] = useState(0);
    // Slider from 1% to 12%, snap to the nearest 1/8th% (0.125)
    const [interestRate, setInterestRate] = useState(0);
    // Numerical entry in dollars (optional)
    const [annualInsurance, setAnnualInsurance] = useState(0);
    // Numerical entry in dollars (optional)
    const [monthlyHOA, setMonthlyHOA] = useState(0);

    // Convert to formula terms
    const P = purchasePrice - downPaymentNumerical;
    const r = interestRate / 12 / 100;
    const n = mortgageTerm * 12;
    const hoa = isNaN(parseFloat(monthlyHOA)) ? 0 : parseFloat(monthlyHOA);

    // Maaaath
    const principalAndInterest = P * r * Math.pow(1 + r, n) / (Math.pow(1 + r, n) - 1);
    const monthlyTaxes = isNaN(annualTaxes / 12) ? 0 : annualTaxes / 12;
    const monthlyInsurance = isNaN(annualInsurance / 12) ? 0 : annualInsurance / 12;
    const totalPayment = principalAndInterest + monthlyTaxes + monthlyInsurance + hoa;
    return (
        <DefaultLayout>
            <div className={styles.calcHeader}>
                <div className={styles.heroImage}>
                    <div className={styles.imageContainer}>
                        <img src={loanCalculator.HeaderImage?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={``} />
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={[styles.heroContent].join(' ')}>
                        <div className={styles.calcHeaderDetails}>
                            <div className={styles.headline}>
                                <div>{loanCalculator.HeaderTitle}</div>
                                <span className={styles.greenLine}></span>
                            </div>
                            <div className={styles.headerDescription}>
                                {loanCalculator.HeaderDescription}
                                <div className={styles.headerButtonWrap}>
                                    <RoundedButton color="blueToWhite" text={loanCalculator.CalculateButtonText} loanOfficer={loanOfficerReferrer} destination="#calculator" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            {/* <div className={styles.howItWorks}>
            <div className={styles.container}>
                <div className={styles.sectionTitle}>
                    <div>{loanCalculator.HowItWorksTitle}</div>
                    <span className={styles.greenLine}></span>
                </div>
                <div className={styles.stepsList}>
                    <div className={styles.stepContainer}>
                        <div className={styles.stepIcon}><img src={loanCalculator?.StepOneIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}/></div>
                        <div className={styles.stepTitle}>{loanCalculator.StepOneLabel}</div>
                        <div className={styles.stepDetails}>{loanCalculator.StepOneDetails}</div>
                    </div>
                    <div className={styles.stepContainer}>
                        <div className={styles.stepIcon}><img src={loanCalculator?.StepTwoIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}/></div>
                        <div className={styles.stepTitle}>{loanCalculator.StepTwoLabel}</div>
                        <div className={styles.stepDetails}>{loanCalculator.StepTwoDetails}</div>
                    </div>
                    <div className={styles.stepContainer}>
                        <div className={styles.stepIcon}><img src={loanCalculator?.StepThreeIcon?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}/></div>
                        <div className={styles.stepTitle}>{loanCalculator.StepThreeLabel}</div>
                        <div className={styles.stepDetails}>{loanCalculator.StepThreeDetails}</div>
                    </div>
            </div>
           </div>
        </div> */}
            <div className={styles.calcPageWrap} id="calculator">
                <div className={styles.container}>
                    <h1>{loanCalculator.PageTitle}</h1>
                    <div className={styles.description}>
                        {loanCalculator.PageDescription}
                    </div>
                    <div className={styles.calculatorDetailsWrap}>
                        <div className={styles.calcInputsWrap}>
                            <div className={styles.twoColumnInputs}>
                                <div className={styles.dollarTextInput}>
                                    <label for="purchasePrice">Purchase Price</label>
                                    <CurrencyInput
                                        id='purchasePrice'
                                        name='purchasePrice'
                                        defaultValue={purchasePrice}
                                        value={validateInput(purchasePrice)}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        onValueChange={e => setPurchasePrice(e)}
                                    />
                                </div>
                                <div>
                                    <label for="mortgageTerm">Mortgage Term (years)</label>
                                    <select id="mortgageTerm" onChange={e => setMortgageTerm(e.target.value)}>
                                        <option value="40">40 Years</option>
                                        <option value="30" selected>30 Years</option>
                                        <option value="20">20 Years</option>
                                        <option value="15">15 Years</option>
                                    </select>
                                </div>
                            </div>
                            <div className={styles.oneColumnInput}>
                                <div className={`${styles.labelInputSideBySide} ${styles.dollarTextInput}`}>
                                    <label for="downPayment">Down Payment ({(downPaymentNumerical / purchasePrice * 100).toFixed(0) >= 100 || (downPaymentNumerical / purchasePrice * 100).toFixed(0) <= 0 || isNaN((downPaymentNumerical / purchasePrice * 100).toFixed(0)) ? `-` : (downPaymentNumerical / purchasePrice * 100).toFixed(0)}%)</label>
                                    <CurrencyInput
                                        id='downPayment'
                                        name='downPayment'
                                        defaultValue={downPaymentNumerical}
                                        decimalsLimit={2}
                                        value={downPaymentNumerical}
                                        allowNegativeValue={false}
                                        max={purchasePrice}
                                        onValueChange={e => calculateDownPayment(null, e)}
                                    />
                                </div>
                                <InputSliderProps
                                    axis="x"
                                    xstep={1}
                                    xmin={0}
                                    xmax={100}
                                    disabled={purchasePrice <= 0 || purchasePrice == undefined}
                                    x={downPaymentNumerical / purchasePrice * 100}
                                    onChange={({ x }) => calculateDownPayment(x, null)}
                                    styles={{
                                        active: {
                                            backgroundColor: '#8DC640'
                                        },
                                        thumb: {
                                            backgroundColor: '#8DC640'
                                        }
                                    }}
                                />
                            </div>
                            <div className={styles.oneColumnInput}>
                                <label for="interestRate">Interest Rate ({interestRate >= 100 || interestRate < 0 || isNaN(interestRate) ? `-` : interestRate}%)</label>
                                <InputSliderProps
                                    id="interestRate"
                                    axis="x"
                                    xstep={1 / 8}
                                    xmin={0}
                                    xmax={12}
                                    x={interestRate}
                                    disabled={purchasePrice <= 0 || purchasePrice == undefined}
                                    onChange={({ x }) => setInterestRate(x)}
                                    styles={{
                                        active: {
                                            backgroundColor: '#8DC640'
                                        },
                                        thumb: {
                                            backgroundColor: '#8DC640'
                                        }
                                    }}
                                />
                            </div>
                            <div className={styles.threeColumnInputs}>
                                <div className={styles.dollarTextInput}>
                                    <label for="annualInsurance">Annual Insurance</label>
                                    <CurrencyInput
                                        id='annualInsurance'
                                        name='annualInsurance'
                                        defaultValue={annualInsurance}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        onValueChange={e => setAnnualInsurance(e)}
                                    />
                                </div>
                                <div className={styles.dollarTextInput}>
                                    <label for="monthlyHOA">Monthly HOA</label>
                                    <CurrencyInput
                                        id='monthlyHOA'
                                        name='monthlyHOA'
                                        defaultValue={monthlyHOA}
                                        value={monthlyHOA}
                                        decimalsLimit={2}
                                        min={0}
                                        allowNegativeValue={false}
                                        onValueChange={e => setMonthlyHOA(e)}
                                    />
                                </div>
                                <div className={styles.dollarTextInput}>
                                    <label for="annualTaxes">Annual Taxes</label>
                                    <CurrencyInput
                                        id='annualTaxes'
                                        name='annualTaxes'
                                        defaultValue={annualTaxes}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        onValueChange={e => setAnnualTaxes(e)}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className={styles.calcResultsWrap}>
                            <div className={styles.paymentResultWrap}>
                                <div>${totalPayment <= 0 || !totalPayment || isNaN(totalPayment) ? `-` : formatNumber(parseFloat(totalPayment).toFixed(2))}</div>
                                <div className={styles.paymentResultLabel}>MONTHLY PAYMENT</div>
                            </div>
                            <div className={styles.paymentDetailsWrap}>
                                <div className={styles.paymentDetailRow}>
                                    <div>Principal &amp; Interest</div>
                                    <div className={styles.detailedResult}>${principalAndInterest <= 0 || !principalAndInterest || isNaN(principalAndInterest) ? `-` : formatNumber(parseFloat(principalAndInterest).toFixed(2))}</div>
                                </div>
                                <div className={styles.paymentDetailRow}>
                                    <div>Monthly Taxes</div>
                                    <div className={styles.detailedResult}>${monthlyTaxes < 0 || !monthlyTaxes || isNaN(monthlyTaxes) ? `-` : formatNumber(parseFloat(monthlyTaxes).toFixed(2))}</div>
                                </div>
                                <div className={styles.paymentDetailRow}>
                                    <div>Monthly HOA</div>
                                    <div className={styles.detailedResult}>${monthlyHOA < 0 || !monthlyHOA || isNaN(monthlyHOA) ? `-` : formatNumber(parseFloat(monthlyHOA).toFixed(2))}</div>
                                </div>
                                <div className={styles.paymentDetailRow}>
                                    <div>Monthly Insurance</div>
                                    <div className={styles.detailedResult}>${monthlyInsurance < 0 || !monthlyInsurance || isNaN(monthlyInsurance) ? `-` : formatNumber(parseFloat(monthlyInsurance).toFixed(2))}</div>
                                </div>
                            </div>
                            <div className={styles.legalDisclaimer} dangerouslySetInnerHTML={{ __html: loanCalculator.CalculatorLegalText }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact loanCalculator={loanCalculator} loanOfficer={loanOfficerReferrer}></Contact>
        </DefaultLayout>
    );
};


const Contact = ({ loanCalculator, loanOfficer }) => {

    if (loanOfficer == null) {
        return (
            <div className={styles.contactSection}>
                <div className={styles.container}>
                    <div className={styles.wrap}>
                        <div className={styles.bg}>
                            <div className={styles.content}>
                                <div className={styles.sectionTitle}>
                                    <div>{loanCalculator.ContactTagline}</div>
                                    <span className={styles.greenLine}></span>
                                </div>
                                <div className={styles.sectionCTAContent}>
                                    <div>{loanCalculator.GetInTouchText}</div>
                                    <div className={styles.buttonRow}>
                                        {loanCalculator.ContactButtonOneText && loanCalculator.ContactButtonOneLink && <RoundedButton color="greenToBlue" text={loanCalculator.ContactButtonOneText} destination={loanCalculator.ContactButtonOneLink} /> }
                                        {loanCalculator.ContactButtonTwoText && loanCalculator.ContactButtonTwoLink && <RoundedButton color="transparentWithGreen" text={loanCalculator.ContactButtonTwoText} destination={loanCalculator.ContactButtonTwoLink} /> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.contactSection}>
                <div className={styles.container}>
                    <div className={styles.wrap}>
                        <div className={styles.bg}>
                            <div className={styles.content}>
                                <div className={styles.sectionTitle}>
                                    <div>{loanCalculator.ContactTagline}</div>
                                    <span className={styles.greenLine}></span>
                                </div>
                                <div className={styles.sectionCTAContent}>
                                    <div>Contact {loanOfficer.firstName} today!</div>
                                    <div className={styles.buttonRow}>
                                        <RoundedButton color="greenToBlue" text='Apply Now' destination={`/${loanOfficer.lastName.toLowerCase()}-${loanOfficer.firstName.toLowerCase()}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export const query = graphql`
 query  {
    directus {
        loanCalculator {
          id
          CalculateButtonText
          CalculatorLegalText
          HeaderDescription
          HeaderImage {
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            id
          }
          HeaderTitle
          HowItWorksTitle
          PageDescription
          PageTitle
          StepOneDetails
          StepOneLabel
          StepThreeDetails
          StepThreeLabel
          StepTwoLabel
          StepOneIcon {
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            id
          }
          StepThreeIcon {
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            id
          }
          StepTwoDetails
          StepTwoIcon {
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            id
          }
          ContactTagline
          GetInTouchText
          ContactButtonOneText
          ContactButtonOneLink
          ContactButtonTwoText
          ContactButtonTwoLink
        }
      }
 }
 `;

export default CalculatorPage;
