import * as React from "react";
import { Link } from "gatsby";
import * as styles from './roundedButton.module.scss'
import { UpperRightArrow } from "../../icons";

const RoundedButton = ({ color, text, destination, loanOfficer }) => {

  return (
    <Link className={styles.buttonContainer} to={destination} state={{ loanOfficer }} target="_blank">
      <span className={[styles.linkSecondary, styles[color]].join(' ')}>
        <UpperRightArrow />
        <span className={styles.text}>{text}</span>
      </span>
    </Link>
  );
}

export default RoundedButton;